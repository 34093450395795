<template>
  <div class="page">
    <div class="tab">申请开门二维码</div>
    <van-field
      v-model="form.message"
      rows="6"
      type="textarea"
      maxlength="200"
      placeholder="请您填写申请二维码理由"
      show-word-limit
    />
    <div class="time_box" @click="toStart">
      <div class="title">开始时间</div>
      <div class="choise">{{ startTime }}</div>
      <img src="./img/arrow.png" alt="" />
    </div>
    <div class="time_box" @click="toEnd">
      <div class="title">结束时间</div>
      <div class="choise">{{ endTime }}</div>
      <img src="./img/arrow.png" alt="" />
    </div>
    <div class="btnBox">
      <div class="btn" @click="submit">立即申请</div>
    </div>
    <!-- 开始时间 -->
    <v-dateTimePicker
      :value="form.startTime"
      :valueShow.sync="actiStartShow"
      :isAuto="true"
      type="datetime"
      :title="'选择时间'"
      @confirmPicker="confirmActiStart"
      :formatter="formatter"
      :filter="filter"
      :columns-order="confirmPicker"
    ></v-dateTimePicker>
    <!-- 结束时间 -->
    <v-dateTimePicker
      :value="form.endTime"
      :valueShow.sync="actiEndShow"
      :isAuto="true"
      type="datetime"
      :title="'选择时间'"
      @confirmPicker="confirmActiEnd"
      :formatter="formatter"
      :filter="filter"
      :columns-order="confirmPicker"
    ></v-dateTimePicker>
  </div>
</template>

<script>
import { Toast } from "vant";
import { applyDoorUrl, userInfoUrl } from "./api.js";
export default {
  name: "applyForQRcode",
  data() {
    return {
      confirmPicker: ["year", "month", "day", "hour", "minute"],
      actiStartShow: false,
      actiEndShow: false,
      startTime: "请选择",
      endTime: "请选择",
      form: {
        message: "",
        startTime: new Date(),
        endTime: new Date(),
      },
      userInfo: {},
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  created() {
    this.getUserInfo();
  },
  mounted() {
    const originalHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        //键盘弹起与隐藏都会引起窗口的高度发生变化
        const resizeHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        console.log("进入到判断页面高度=========");
        console.log("页面初始高度=========" + originalHeight);
        console.log("软键盘弹起高度=========" + resizeHeight);
        if (resizeHeight - 0 < originalHeight - 0) {
          //当软键盘弹起，在此处操作
          Toast("软键盘弹起");
          console.log("进入到软键盘弹起=========");
          document
            .querySelector("body")
            .setAttribute("style", "height:" + originalHeight + "px;");
          this.scrollerHeight = resizeHeight;
        } else {
          Toast("软键盘收起");
          //当软键盘收起，在此处操作
          console.log("进入到软键盘收起=========");
          document.querySelector("body").setAttribute("style", "height:100%;");
          this.scrollerHeight = "100%";
        }
      })();
    };
  },
  methods: {
    getUserInfo() {
      let params = {
        userId: this.userId,
      };

      this.$axios
        .get(`${userInfoUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.userInfo = res.data;
          }
        });
    },
    submit() {
      //   if (!this.form.message || this.form.message == "") {
      //     this.$toast({
      //       message: "申请二维码理由不能为空！",
      //       duration: 1000,
      //     });
      //     return;
      //   }
      //   if (this.startTime == "请选择") {
      //     this.$toast({
      //       message: "开始时间不能为空！",
      //       duration: 1000,
      //     });
      //     return;
      //   }
      //   if (this.endTime == "请选择") {
      //     this.$toast({
      //       message: "结束时间不能为空！",
      //       duration: 1000,
      //     });
      //     return;
      //   }
      //   if (this.endTime == this.startTime) {
      //     this.$toast({
      //       message: "开始时间和结束时间不能相同！",
      //       duration: 1000,
      //     });
      //     return;
      //   }
      //   if (this.endTime < this.startTime) {
      //     this.$toast({
      //       message: "结束时间不能小于开始时间！",
      //       duration: 1000,
      //     });
      //     return;
      //   }
      //   let params = {
      //     userId: this.userId,
      //     applyPlace: "邻里中心",
      //     applyReason: this.form.message,
      //     beginTime: this.startTime,
      //     endTime: this.endTime,
      //     userName: this.userInfo.userName,
      //     userCall: this.userInfo.nickname,
      //     telephone: this.userInfo.mobile,
      //   };
      //   this.$axios.post(`${applyDoorUrl}`, params).then((res) => {
      //     if (res.code === 200) {
      //       this.$toast({
      //         message: res.data,
      //         duration: 1000,
      //       });
      //       this.$router.push({
      //         path: "commonCode",
      //       });
      //     } else {
      //     }
      //   });
    },
    filter(type, options) {
      if (type === "minute") {
        return options.filter((option) => option % 30 === 0);
      }
      return options;
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    transformTime(date) {
      let d = new Date(date);
      let year = d.getFullYear() + "-";
      let month =
        d.getMonth() + 1 < 10
          ? "0" + (d.getMonth() + 1) + "-"
          : d.getMonth() + 1 + "-";
      let day = d.getDate() < 10 ? "0" + d.getDate() + "-" : d.getDate() + " ";
      let hour =
        d.getHours() < 10 ? "0" + d.getHours() + ":" : d.getHours() + ":";
      let minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      let datetime = year + month + day + hour + minutes;
      return datetime;
    },
    toStart() {
      this.actiStartShow = true;
    },
    toEnd() {
      this.actiEndShow = true;
    },
    confirmActiStart(value) {
      console.log(value);
      this.form.startTime = value;
      this.startTime = this.transformTime(value);
    },
    confirmActiEnd(value) {
      console.log(value);
      this.form.endTime = value;
      this.endTime = this.transformTime(value);
    },
  },
};
</script>

<style lang="less" scoped>
body,
html {
  height: 100%;
}

.page {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  // box-sizing: border-box;

  .btnBox {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 189px;
    height: 96px;

    .btn {
      width: 628px;
      height: 100%;
      background: #007eff;
      border-radius: 16px;
      line-height: 96px;
      text-align: center;
      margin: 0 auto;
      font-size: 32px;
      font-weight: 600;
      color: #ffffff;
    }
  }

  .time_box {
    width: 686px;
    height: 84px;
    margin: 0 auto;
    position: relative;

    img {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
    }

    .choise {
      position: absolute;
      top: 50%;
      right: 34px;
      transform: translateY(-50%);
      font-size: 32px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }

    .title {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      font-size: 36px;
      font-weight: 400;
      color: #333333;
    }
  }

  .van-cell {
    width: 686px;
    background: #f7f8fa;
    border-radius: 16px;
    margin: 0 auto;
    margin-bottom: 15px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #323334;

    .van-field__word-limit {
      color: #cbcccd !important;
    }
  }

  .tab {
    width: 100%;
    box-sizing: border-box;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    padding: 46px 0 20px 30px;
  }
}
</style>
